import React from "react";
import * as auth0Client from "../utils/auth0Client";

const AuthLogout = () => {
  React.useEffect(() => {
    auth0Client.logout();
    const closeIframe = () => {
      const authIF = window.parent.document.getElementById('authIframe');
      authIF && authIF.parentNode.removeChild(authIF);
    };
    closeIframe();
  }, []);
  return null;
};

export default AuthLogout;